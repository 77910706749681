import React, { Component } from "react";
import styles from "./layout.module.css";
import MobileLayout from "./mobile-layout.js";
import { Link } from "gatsby";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
        this.state = {
            width: 995
        }
    }

    componentDidMount() {
        this.setState({
            width: window.innerWidth
        })
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({
            width: window.innerWidth
        })
    }

    render() {

        if (this.state.width > 995) {
            return (
                <div className={styles.container}>
                    <div className={styles.sidebar}>
                        <nav className={styles.navigation}>
                            <h1 className={styles.brand}>RIVER CITY DRAFTHOUSE</h1>
                            <p className={styles.subheader}>Family owned business specializing in American & Asian dishes, craft beer with over 20 local and national offerings on tap and many more in bottles. Amazing wings and fresh Sushi also await you, so there is sure to be something for everyone. </p>
                            <Link to="/home"className={styles.link}>Home</Link>
                            <Link to="/specials"className={styles.link}>Happy Hour and Specials</Link>
                            <Link to="/" className={styles.link}>Beer</Link>
                            <Link to="/menu"className={styles.link}>Menu</Link>
                            <Link to="/sushi"className={styles.link}>Sushi</Link>
                            <Link to="/contact/"className={styles.link}>Contact Us</Link> 
                            
                            
                        </nav>
                    </div>
                    <main className={styles.content}>
                        {this.props.children}
                    </main>
                </div>
            )
        }
        else {
            return (
                <MobileLayout>
                    {this.props.children}
                </MobileLayout>
            )
        }
    }
}

export default Layout;